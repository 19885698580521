import React from 'react';

const PDFDisplay: React.FC<any> = ({ pdf, className = '' }) => {
  const pdfUrl = `${pdf}#toolbar=1&navpanes=0&scrollbar=0&statusbar=0&messages=0&view=FitH&transparent=1`;

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <div className="relative rounded-lg border border-gray-200 bg-white">
        <iframe src={pdfUrl} className="h-[600px] w-full rounded-lg" />
      </div>
    </div>
  );
};

export default PDFDisplay;
