import { formatCustomerGroup } from 'helpers/formatter';

const usePermissionsChecker = () => {
  const formattedCustomerGroup = (customerGroup: any) => {
    return formatCustomerGroup(customerGroup?.obj?.key!);
  };

  const getTagId = (tag: any) => tag.sys.id;
  const isMatchingTag = (tagId: string, customerGroup: any) =>
    tagId === formattedCustomerGroup(customerGroup) || tagId === 'allUsers';

  const filterByTags = (items: any[], customerGroup: any) =>
    items?.filter((item) => item?.metadata?.tags?.some((tag: any) => isMatchingTag(getTagId(tag), customerGroup)));

  const getFields = (dataItem: any) => dataItem?.fields;

  return { formattedCustomerGroup, getTagId, filterByTags, getFields };
};

export default usePermissionsChecker;
