import React from 'react';
import { Popover } from '@headlessui/react';

type Props = {
  className?: string;
  totalCartItems?: number;
  counterClassName?: string;
  icon?: any;
};

const Icon: React.FC<Props> = ({ className, totalCartItems = 0, counterClassName, icon }) => (
  <div className="relative">
    {totalCartItems > 0 && (
      <>
        <span className="absolute right-[-6px] top-[-3px] h-10 w-10 rounded-full bg-green-500" />
        <span
          className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-9 text-primary-regular ${counterClassName}`}
        >
          {totalCartItems}
        </span>
      </>
    )}
    <img className={className} src={icon}></img>
  </div>
);

export default Icon;
