import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { Reference } from 'types/reference';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';

export interface HeroProps {
  image: string;
  title: string;
  subtitle: string;
  ctaLabel?: string;
  ctaReference?: Reference;
}

const Hero: React.FC<HeroProps> = ({ image, title, subtitle, ctaLabel, ctaReference }) => {
  return (
    <div className="relative col-span-8 col-start-2 w-full">
      {image && (
        <div className="relative h-[296px] md:h-[532px] lg:h-[668px]">
          <Image src={image} layout="fill" objectFit="cover" priority={true} />
        </div>
      )}
      <div className="absolute left-0 top-1/2 w-full -translate-y-1/2 px-48 text-center">
        <Typography align="center" fontSize={14} className="text-white md:text-18">
          {subtitle}
        </Typography>
        <Typography align="center" className="mt-22 text-36 text-white md:mt-32 md:text-58" fontFamily="NunitoSansBold">
          {title}
        </Typography>
        {ctaLabel && (
          <Link link={ctaReference}>
            <Button className="mt-22 bg-secondary-regular hover:bg-secondary-regular hover:opacity-50 md:mt-36 md:px-48 md:py-12 lg:mt-32">
              <Typography fontFamily="secondaryBold" className="text-16 uppercase text-neutral-150 md:text-20">
                {ctaLabel}
              </Typography>
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Hero;
