import React from 'react';
import EcoSection from 'components/commercetools-ui/organisms/eco-section';
import Card, { CardProps } from 'components/commercetools-ui/organisms/eco-section/card';
import { formatCustomerGroup } from 'helpers/formatter';

interface Props {
  data: any;
  customerGroup: any;
}

const MindfullyMadeTastic: React.FC<Props> = ({ data, customerGroup }) => {
  if (!data) {
    return <div>Loading...</div>;
  }

  const formattedCustomerGroup = formatCustomerGroup(customerGroup?.obj?.key!);

  const getTagId = (tag: any) => tag.sys.id;
  const isMatchingTag = (tagId: string) => tagId === formattedCustomerGroup || tagId === 'allUsers';

  const filterByTags = (items: any[]) =>
    items?.filter((item) => item.metadata?.tags?.some((tag: any) => isMatchingTag(getTagId(tag))));

  const containsMatchingTag = (dataItem: any) =>
    dataItem?.metadata?.tags?.some((tag: any) => isMatchingTag(getTagId(tag)));

  const getFields = (dataItem: any) => dataItem?.fields;

  const dataSourceItems = data?.data?.dataSource?.items[0]?.fields;

  const cards = filterByTags(dataSourceItems?.columns)?.map(getFields);

  return (
    <div className="mb-60 mt-52 flex uppercase">
      {
        <div className="grid grid-cols-1 gap-50 md:grid-cols-3 lg:grid-cols-3">
          {cards?.map((card, index) => (
            <div key={index}>
              <Card
                image={card?.image}
                subHeader={card.subHeader}
                title={card.title}
                ecoParagraph={card.ecoParagraph}
                ctaLabel={card.ctaLabel}
                ctaReference={card.ctaReference}
              />
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default MindfullyMadeTastic;
