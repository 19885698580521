import React from 'react';
import Footer, { FooterColumn, Props as FooterProps, SocialMedia } from 'components/commercetools-ui/organisms/footer';
import { FooterLink } from 'components/commercetools-ui/organisms/footer/atoms/column';
import { Reference } from 'types/reference';
import { NextFrontasticImage } from 'frontastic/lib/image/types';
import { formatCustomerGroup } from 'helpers/formatter';

interface Props {
  data: any;
  customerGroup: any;
}
const FooterCmsTastic = ({ data, customerGroup }: Props) => {
  const formattedCustomerGroup = formatCustomerGroup(customerGroup?.obj?.key!);

  const getTagId = (tag: any) => tag.sys.id;
  const isMatchingTag = (tagId: string) => tagId === formattedCustomerGroup || tagId === 'allUsers';

  const filterByTags = (items: any[]) =>
    items?.filter((item) => item.metadata.tags.some((tag: any) => isMatchingTag(getTagId(tag))));

  const getFields = (dataItem: any) => dataItem?.fields;

  const rawColumns = data?.data?.dataSource?.items?.[0]?.fields?.columns;
  const rawSocialMedia = data?.data?.dataSource?.items?.[0]?.fields?.socialMedia;

  const columns = [] as never[] as FooterColumn[];
  rawColumns?.forEach((column: any) => {
    const filteredLinks = filterByTags(column.fields.link)?.map(getFields);
    const links = filteredLinks?.map((link: any) => {
      return {
        name: link?.linkName,
        icon: link?.linkIcon?.fields?.file?.url,
        reference: {
          type: 'link',
          link: link?.linkUrl,
          openInNewWindow: link?.openLinkInNewTab,
        } as Reference,
      } as FooterLink;
    }) as FooterLink[];

    const footerColumns = {
      header: column?.fields?.title as string,
      links: links,
    } as FooterColumn;
    columns.push(footerColumns);
  });

  const socialMediaList = [] as never[] as SocialMedia[];
  rawSocialMedia?.forEach((socialMedia: any) => {
    const logo = {
      media: {
        file: socialMedia?.fields?.linkIcon?.fields?.file?.url,
        name: socialMedia?.fields?.linkIcon?.fields?.file?.fileName,
        width: socialMedia?.fields?.linkIcon?.fields?.file?.details?.image?.width,
        height: socialMedia?.fields?.linkIcon?.fields?.file?.details?.image?.height,
      },
    } as NextFrontasticImage;
    const reference = {
      type: 'link',
      link: socialMedia?.fields?.linkUrl,
      openInNewWindow: socialMedia?.fields?.openLinkInNewTab,
    } as Reference;

    socialMediaList.push({ logo, reference });
  });

  return (
    <div className="fixed-screen-width lg:relative-width">
      <Footer columns={columns} socialMedia={socialMediaList} />
    </div>
  );
};

export default FooterCmsTastic;
