import React from 'react';
import StaticPage from 'components/commercetools-ui/organisms/static-page';
import { staticPageDataMapper } from '../utils';

export interface Props {
  data: any;
}

const StaticPageTastic: React.FC<Props> = ({ data }) => {
  const mappedData = staticPageDataMapper(data);

  return <StaticPage initialData={mappedData} />;
};

export default StaticPageTastic;
