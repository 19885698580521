import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';

interface Image {
  fields: {
    file: {
      url: string;
    };
  };
}
export interface CardProps {
  image?: Image;
  subHeader: string;
  title: string;
  ecoParagraph: string;
  ctaLabel: string;
  ctaReference: string;
}

const Card: React.FC<CardProps> = ({ image, subHeader, title, ecoParagraph, ctaLabel, ctaReference }) => {
  return (
    <div className="margin flex w-full flex-col items-center">
      {image ? (
        <img
          src={image?.fields?.file?.url}
          alt="Component Image"
          className="h-auto max-h-[9rem] w-full lg:max-h-[16rem]"
        />
      ) : (
        <div className="h-240 w-full bg-[#451400]" />
      )}
      <div className="mt-15 flex w-full flex-col items-center">
        <h3 className="text-2xl font-semibold text-[#451400]">
          <Typography fontSize={28}>{subHeader}</Typography>
        </h3>
        <Typography className="w-full text-center text-20 text-[#451400] lg:text-28" lineHeight="7">
          {title}
        </Typography>
        <p className="mt-15 text-lg">
          <Typography lineHeight="7" className="text-center text-12 capitalize text-[#451400] lg:text-14">
            {ecoParagraph}
          </Typography>
        </p>
        <a href={ctaReference} className="mt-15 capitalize text-[#451400] underline">
          <Typography className="text-center text-12 lg:text-14">{ctaLabel}</Typography>
        </a>
      </div>
    </div>
  );
};

export default Card;
