import { Product } from '@commercetools/frontend-domain-types/product/Product';
import ProductSlider, { ProductSliderProps } from 'components/commercetools-ui/organisms/product/product-slider';
import { useProduct } from 'frontastic/hooks';
import { useEffect, useState } from 'react';
import usePermissionsChecker from 'helpers/hooks/usePermissionsChecker';
import { toUIProductSlider } from 'helpers/mappers/toUIProductSlider';

interface Props {
  data: any;
  customerGroup: any;
}

function ProductFeaturedSliderTastic({ data, customerGroup }: Props) {
  const { getProductsByCategories } = useProduct();
  const [products, setProducts] = useState<Product[]>([]);
  const [properties, setProperties] = useState<ProductSliderProps>();
  const { filterByTags, getFields } = usePermissionsChecker();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const categories = data?.data?.dataSource?.items?.[0]?.fields.categoryLinks;
    const filteredCategories = filterByTags(categories, customerGroup)?.map(getFields);
    const products = (await getProductsByCategories(filteredCategories?.[0]?.categoryLinks)) as Product[];

    const props = toUIProductSlider(filteredCategories?.[0]);
    setProperties(props);
    setProducts(products);
  };
  const { mobile, tablet, desktop, ...props } = data;

  return products?.length > 0 ? <ProductSlider {...properties} products={products} /> : <></>;
}

export default ProductFeaturedSliderTastic;
