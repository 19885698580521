import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { MagnifyingGlassIcon as SearchIcon, XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import Search from 'components/commercetools-ui/atoms/search';
import HeaderNavigationButtonDesktop from 'components/commercetools-ui/organisms/header/header-navigation/header-navigation-desktop/header-navigation-button';
import useClassNames from 'helpers/hooks/useClassNames';
import useScrollDirection from 'helpers/hooks/useScrollDirection';

export interface Props {
  links: any[]; //TODO FIX THIS TYPE
  categories: any;
  search: boolean;
}

const HeaderNavigationDesktop: React.FC<Props> = ({ links, categories, search }) => {
  const scrollDirection = useScrollDirection(5, -1);
  const navigationClassNames = useClassNames([
    'hidden items-center xl:flex duration-150 transition-all w-fit',
    scrollDirection === 'down' ? 'h-0 pointer-events-none' : 'h-52 opacity-1 pointer-events-auto',
  ]);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const router = useRouter();

  const onSearchClicked = () => {
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsDrawerOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  return (
    <div>
      {links && (
        <div className={navigationClassNames}>
          <div className="flex w-fit justify-start">
            {links.map((link, index) => (
              <div key={index}>
                <HeaderNavigationButtonDesktop link={link.linkUrl} linkName={link.linkName} />
              </div>
            ))}
            {search && (
              <div className="relative mx-10 hidden h-32 xl:block ">
                <div
                  className="mt-10 h-full cursor-pointer border-primary-regular hover:border-b-2"
                  onClick={onSearchClicked}
                >
                  <SearchIcon className={`h-24 w-24 stroke-0 ${'fill-primary-regular'}`} />
                </div>
                <Drawer
                  isOpen={isDrawerOpen}
                  direction="under"
                  className="bg-white"
                  onClose={() => setIsDrawerOpen(false)}
                >
                  <Search categories={categories} />
                </Drawer>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderNavigationDesktop;
