interface MenuItem {
  id: string;
  label: string;
  href: string;
}

interface SideMenuProps {
  items: MenuItem[];
  activeId?: string;
  navigationTitle?: string;
}

const SideMenu: React.FC<SideMenuProps> = ({ items, activeId, navigationTitle }) => {
  return (
    <div className="my-32 lg:col-span-2">
      {navigationTitle && <p className=" pb-5 text-left font-body text-28 leading-normal">{navigationTitle}</p>}
      <ul className="sticky top-[150px] text-base font-medium">
        {items.map((item) => (
          <li key={item.id} className={`border-b py-10 ${activeId === item.id && 'active'}`}>
            <a href={item.href}>{item.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
