import Link from 'next/link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS, Document } from '@contentful/rich-text-types';

interface RichTextProps {
  content: Document;
  className?: string;
  tableHeadColor?: string;
  tableHeadTextColor?: string;
  tableBodyColor?: string;
  tableBodyTextColor?: string;
}

const RichText: React.FC<RichTextProps> = ({
  content,
  className,
  tableHeadColor,
  tableHeadTextColor,
  tableBodyColor,
  tableBodyTextColor,
}) => {
  const options = {
    preserveWhitespace: true,
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode) => <strong className="font-body">{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => (
        <p className={`text-left xl:text-justify ${className}`}>{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node: any, children: React.ReactNode) => (
        <h1 className="mb-24 text-4xl font-bold">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node: any, children: React.ReactNode) => (
        <h2 className="mb-20 text-3xl font-bold">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node: any, children: React.ReactNode) => (
        <h3 className="mb-16 text-2xl font-bold">{children}</h3>
      ),
      [BLOCKS.UL_LIST]: (node: any, children: React.ReactNode) => <ul className="mb-24 list-disc pl-24">{children}</ul>,
      [BLOCKS.OL_LIST]: (node: any, children: React.ReactNode) => (
        <ol className="mb-24 list-decimal pl-24">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode) => <li className="mb-8">{children}</li>,
      [BLOCKS.TABLE]: (node: any, children: React.ReactNode) => (
        <div className="overflow-x-auto">
          <table className="h-16 w-full table-auto divide-y divide-gray-200 border">{children}</table>
        </div>
      ),
      [BLOCKS.TABLE_ROW]: (node: any, children: React.ReactNode) => {
        const isHeaderRow = node.content.some((cell: any) => cell.nodeType === 'table-header-cell');
        return !isHeaderRow ? (
          <tr className={`${tableBodyColor || ''} ${tableBodyTextColor || ''}`}>{children}</tr>
        ) : (
          <thead className={`${tableHeadColor || 'bg-gray-200'} ${tableHeadTextColor || ''} text-left`}>
            {children}
          </thead>
        );
      },
      [BLOCKS.TABLE_CELL]: (node: any, children: React.ReactNode) => (
        <td className="px-4 py-2 text-left">{children}</td>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (node: any, children: React.ReactNode) => (
        <th className="px-4 py-3 text-left font-semibold">{children}</th>
      ),
      [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => {
        const uri = node.data.uri;

        if (uri.startsWith('http') || uri.startsWith('mailto:') || uri.startsWith('tel:')) {
          return (
            <a
              href={uri}
              className="text-primary hover:text-primary/80 underline underline-offset-4"
              target="_blank"
              rel="noreferrer"
            >
              {children}
            </a>
          );
        }

        return (
          <Link href={uri} className="text-primary hover:text-primary/80 underline underline-offset-4">
            {children}
          </Link>
        );
      },
    },
  };

  return <div className={`w-full ${className}`}>{documentToReactComponents(content, options)}</div>;
};

export default RichText;
