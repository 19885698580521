import Link from 'next/link';
import { ContentImage } from '../../types';

interface ImageGalleryProps {
  images: ContentImage[];
  layout?: 'inline' | 'grid';
  className?: string;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, layout = 'inline' }) => {
  const gridCols = images.length < 3 ? `grid-cols-${images.length}` : 'md:grid-cols-2 lg:grid-cols-3';

  const imageContainerClass = `${
    layout === 'grid' ? `grid ${gridCols}` : 'flex flex-col items-center justify-center md:flex-row'
  }`;

  return images.length ? (
    <div className={`${imageContainerClass} gap-8`}>
      {images.map((image, index) => (
        <ImageWithLink
          key={index}
          image={image}
          className={`${images.length === 1 || layout === 'grid' ? 'w-full' : null}`}
        />
      ))}
    </div>
  ) : null;
};

const ImageWithLink = ({ image, className }: { image: ContentImage; className?: string; layout?: string }) => {
  const img = <img src={image.src} alt={image.alt} className={`mx-auto ${image.imageWidth || 'w-full'}`} />;

  if (image.link) {
    return (
      <Link
        href={image.link}
        className={className}
        target={image.link.startsWith('http') ? '_blank' : undefined}
        rel={image.link.startsWith('http') ? 'noopener noreferrer' : undefined}
      >
        {img}
      </Link>
    );
  }

  return <div className={className}>{img}</div>;
};

export default ImageGallery;
