import { Reference } from 'types/reference';
import { ProductSliderProps } from 'components/commercetools-ui/organisms/product/product-slider';
export const toUIProductSlider = (filteredCategory: any) => {
  return {
    title: filteredCategory?.title,
    subline: filteredCategory?.subline,
    products: filteredCategory?.products,
    ctaLabel: filteredCategory?.link?.fields?.linkName,
    ctaLink: linkToRefenece(filteredCategory?.link?.fields?.linkUrl, filteredCategory?.link?.fields?.openLinkInNewTab),
  } as ProductSliderProps;
};

const linkToRefenece = (linkUrl?: string, openLinkInNewTab?: boolean) => {
  if (linkUrl) {
    return {
      type: 'link',
      link: linkUrl,
      openInNewWindow: openLinkInNewTab,
    } as Reference;
  }
};
