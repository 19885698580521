import React from 'react';
import Search from 'components/commercetools-ui/atoms/search';
import HeaderLogo from 'components/commercetools-ui/organisms/header/header-logo';
import HeaderNavigationDesktop from 'components/commercetools-ui/organisms/header/header-navigation/header-navigation-desktop';
import HeaderNavigationMobile from 'components/commercetools-ui/organisms/header/header-navigation/header-navigation-mobile';
import { HeaderProps } from 'components/commercetools-ui/organisms/header/types';
import UtilitySection from 'components/commercetools-ui/organisms/header/utility-section';

const Header: React.FC<HeaderProps> = ({
  navLinks,
  categories,
  logo,
  logoMobile,
  logoLink,
  logoLinkMobile,
  src,
  icon,
  search,
}) => {
  return (
    <header className="relative h-fit w-full border-b-[1.5px] border-neutral-400 bg-white py-20">
      <div
        aria-label="Top"
        className="mb-10 flex w-full items-stretch justify-between px-16 md:px-24 lg:px-20 xl:mb-0 xl:items-center xl:px-48"
      >
        <HeaderNavigationMobile src={src} logo={logo} links={navLinks} logoLink={logoLink} />

        <div className="ml-0 flex w-full justify-start lg:ml-0 xl:ml-0 xl:w-fit">
          <HeaderLogo
            src={src}
            logo={logo}
            logoLink={logoLink}
            imageClassName="flex h-40 w-130 justify-center text-center text-16 font-bold lg:h-50 lg:w-150 md:text-28"
          />
        </div>
        <HeaderNavigationDesktop links={navLinks} categories={categories} search={search} />
        <UtilitySection icon={icon} />
      </div>
      {!!search && (
        <div className="relative block w-full border-t border-neutral-400 px-16 py-8 md:px-24 lg:px-20 xl:hidden xl:px-48">
          <Search categories={categories} />
        </div>
      )}
    </header>
  );
};
export default Header;
