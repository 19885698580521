import React from 'react';
import Minicard, { MiniCard as MiniCardProps } from './mini-card';
import Title, { Title as TitleProps } from './title';

export interface MiniCardContainerProps {
  miniCards: MiniCardProps[];
  titleInfo: TitleProps;
}

const MiniCardContainer: React.FC<MiniCardContainerProps> = ({ miniCards, titleInfo }) => {
  const cards = () => {
    return miniCards?.map((miniCard, index) => {
      return <Minicard key={index} miniCard={miniCard} />;
    });
  };

  return (
    <>
      <Title title={titleInfo}></Title>
      <div className="-ml-10 hidden space-x-10 lg:flex">{cards()}</div>
    </>
  );
};

export default MiniCardContainer;
